import React from 'react';
import { 
  Paper, 
  Toolbar, 
  TextField, 
  FormControl, 
  Select, 
  MenuItem, 
  IconButton, 
  Box, 
  Typography, 
  Tooltip,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import GetAppIcon from '@mui/icons-material/GetApp';
import { TICKET_TYPES } from '../utils/constants';
import TicketTable from './TicketTable';
import TicketDialog from './TicketDialog';

const Dashboard = ({
  searchQuery,
  onSearchChange,
  ticketTypeFilter,
  onTicketTypeFilterChange,
  onRefresh,
  onExport,
  selectedTickets,
  tickets,
  loading,
  error,
  onRetry,
  page,
  rowsPerPage,
  orderBy,
  order,
  onSort,
  onPageChange,
  onRowsPerPageChange,
  onTicketSelect,
  onSelectAll,
  selectedTicket,
  onTicketClick,
  onCloseDialog
}) => {
  return (
    <>
      <Paper sx={{ mb: 2, p: 2 }}>
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
          <TextField
            size="small"
            placeholder="Search tickets..."
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: 300, mr: 2 }}
          />
          <FormControl size="small" sx={{ minWidth: 120, mr: 2 }}>
            <Select
              value={ticketTypeFilter}
              onChange={(e) => onTicketTypeFilterChange(e.target.value)}
              displayEmpty
            >
              <MenuItem value={TICKET_TYPES.ALL}>All Tickets</MenuItem>
              <MenuItem value={TICKET_TYPES.INCIDENTS}>Incidents Only</MenuItem>
              <MenuItem value={TICKET_TYPES.CHANGES}>Changes Only</MenuItem>
            </Select>
          </FormControl>
          <IconButton onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          {selectedTickets.length > 0 && (
            <Typography color="inherit" variant="subtitle1" component="div" sx={{ mr: 2 }}>
              {selectedTickets.length} selected
            </Typography>
          )}
          <Tooltip title="Export">
            <IconButton onClick={onExport}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Paper>
      
      <TicketTable
        tickets={tickets}
        selectedTickets={selectedTickets}
        onTicketSelect={onTicketSelect}
        onSelectAll={onSelectAll}
        onTicketClick={onTicketClick}
        orderBy={orderBy}
        order={order}
        onSort={onSort}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        loading={loading}
        error={error}
        onRetry={onRetry}
      />
      
      <TicketDialog
        ticket={selectedTicket}
        onClose={onCloseDialog}
      />
    </>
  );
};

export default Dashboard;