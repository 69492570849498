// src/components/ServicePortal.js
import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Grid, 
  Button, 
  Snackbar, 
  Alert, 
  CardContent,
  CircularProgress
} from '@mui/material';
import { View } from "@aws-amplify/ui-react";
import { fetchIncidents, fetchChanges, submitIncident, submitChange } from '../api/PortalAPI';
import { StyledCard, ResponsiveLogo, ResponsiveLogoB } from './StyledComponents';
import { validateEmail, validatePhone, validateName } from '../utils/validators';
import { TICKET_TYPES } from '../utils/constants';
import Dashboard from './Dashboard';
import IncidentForm from './IncidentForm';
import ChangeForm from './ChangeForm';

const ServicePortal = () => {
  const [activeView, setActiveView] = useState('');
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('ticketNumber');
  const [order, setOrder] = useState('asc');
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [ticketTypeFilter, setTicketTypeFilter] = useState(TICKET_TYPES.ALL);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    priority: '',
    title: '',
    description: '',
    firstName: '',
    lastName: '',
    requestedBy: '',
    phoneNumber: '',
    // Change-specific fields
    changeType: '',
    plannedStart: '',
    plannedEnd: '',
    justification: ''
  });
  

  const [formErrors, setFormErrors] = useState({
    priority: '',
    title: '',
    description: '',
    firstName: '',
    lastName: '',
    requestedBy: '',
    phoneNumber: '',
    equipmentType: '',
    equipmentModel: ''
  });

  const fetchAllTickets = async () => {
    setLoading(true);
    setError(null);
    try {
      const [incidents, changes] = await Promise.all([
        fetchIncidents(),
        fetchChanges()
      ]);
      setTickets([...incidents, ...changes]);
    } catch (err) {
      setError('Failed to fetch tickets. Please try again later.');
      console.error('Error fetching tickets:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeView === 'dashboard') {
      fetchAllTickets();
    }
  }, [activeView]);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({...prev, [field]: value}));
    
    let error = '';
    switch(field) {
      case 'requestedBy':
        error = validateEmail(value) ? '' : 'Invalid email address';
        break;
      case 'phoneNumber':
        error = validatePhone(value) ? '' : 'Invalid phone number (10 digits required)';
        break;
      case 'firstName':
      case 'lastName':
        error = validateName(value) ? '' : 'Invalid name (letters, spaces, and hyphens only)';
        break;
      default:
        break;
    }
    setFormErrors(prev => ({...prev, [field]: error}));
  };

  const validateIncidentForm = () => {
    const newErrors = {};
    if (!formData.priority) newErrors.priority = 'Priority is required';
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.description) newErrors.description = 'Description is required';
    if (!selectedEquipmentType) newErrors.equipmentType = 'Equipment type is required';
    if (!selectedModel) newErrors.equipmentModel = 'Equipment model is required';
    if (!validateEmail(formData.requestedBy)) newErrors.requestedBy = 'Valid email is required';
    if (!validatePhone(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
    if (!validateName(formData.firstName)) newErrors.firstName = 'Valid first name is required';
    if (!validateName(formData.lastName)) newErrors.lastName = 'Valid last name is required';
  
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateChangeForm = () => {
    const newErrors = {};
    if (!formData.changeType) newErrors.changeType = 'Change type is required';
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.description) newErrors.description = 'Description is required';
    if (!formData.plannedStart) newErrors.plannedStart = 'Planned start date is required';
    if (!formData.plannedEnd) newErrors.plannedEnd = 'Planned end date is required';
    if (!formData.justification) newErrors.justification = 'Justification is required';
    if (!validateEmail(formData.requestedBy)) newErrors.requestedBy = 'Valid email is required';
    if (!validatePhone(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
    if (!validateName(formData.firstName)) newErrors.firstName = 'Valid first name is required';
    if (!validateName(formData.lastName)) newErrors.lastName = 'Valid last name is required';
  
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFormData({
      priority: '',
      title: '',
      description: '',
      firstName: '',
      lastName: '',
      requestedBy: '',
      phoneNumber: '',
      changeType: '',
      plannedStart: '',
      plannedEnd: '',
      justification: ''
    });
    // Only reset equipment type and model if we're in incident view
    if (activeView === 'incident') {
      setSelectedEquipmentType('');
      setSelectedModel('');
    }
    setFormErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const isValid = activeView === 'incident' 
      ? validateIncidentForm() 
      : validateChangeForm();
  
    if (!isValid) {
      setSnackbar({
        open: true,
        message: 'Please complete all required fields correctly',
        severity: 'error'
      });
      return;
    }
  
    setSubmitting(true);
    try {
      const submitData = {
        ...formData,
        ...(activeView === 'incident' ? {
          equipmentType: selectedEquipmentType,
          equipmentModel: selectedModel
        } : {})
      };
      
      if (activeView === 'incident') {
        await submitIncident(submitData);
        setSnackbar({
          open: true,
          message: 'Incident submitted successfully',
          severity: 'success'
        });
      } else if (activeView === 'change') {
        await submitChange(submitData);
        setSnackbar({
          open: true,
          message: 'Change request submitted successfully',
          severity: 'success'
        });
      }
      
      resetForm();
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || `Failed to submit ${activeView}`,
        severity: 'error'
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getFilteredTickets = () => {
    return tickets
      .filter(ticket => {
        if (ticketTypeFilter === TICKET_TYPES.ALL) return true;
        if (ticketTypeFilter === TICKET_TYPES.INCIDENTS) return ticket.type === 'Incident';
        if (ticketTypeFilter === TICKET_TYPES.CHANGES) return ticket.type === 'Change';
        return true;
      })
      .filter(ticket => 
        Object.values(ticket).some(value => 
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
  };

  return (
    <View>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <ResponsiveLogo />
          <Typography variant="h4" component="h1">
            ACG Service Portal
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant={activeView === 'incident' ? 'contained' : 'outlined'}
              onClick={() => setActiveView('incident')}
              sx={{ height: '100px' }}
            >
              Open Incident
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant={activeView === 'change' ? 'contained' : 'outlined'}
              onClick={() => setActiveView('change')}
              sx={{ height: '100px' }}
            >
              Open Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant={activeView === 'dashboard' ? 'contained' : 'outlined'}
              onClick={() => setActiveView('dashboard')}
              sx={{ height: '100px' }}
            >
              Dashboard
            </Button>
          </Grid>
        </Grid>

        {activeView === 'dashboard' && (
          <Dashboard
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
            ticketTypeFilter={ticketTypeFilter}
            onTicketTypeFilterChange={setTicketTypeFilter}
            onRefresh={fetchAllTickets}
            selectedTickets={selectedTickets}
            tickets={getFilteredTickets()}
            loading={loading}
            error={error}
            onRetry={fetchAllTickets}
            page={page}
            rowsPerPage={rowsPerPage}
            orderBy={orderBy}
            order={order}
            onSort={handleSort}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
            onTicketSelect={(ticketNumber) => {
              const newSelected = selectedTickets.includes(ticketNumber)
                ? selectedTickets.filter(id => id !== ticketNumber)
                : [...selectedTickets, ticketNumber];
              setSelectedTickets(newSelected);
            }}
            onSelectAll={(event) => {
              if (event.target.checked) {
                setSelectedTickets(getFilteredTickets().map(t => t.ticketNumber));
              } else {
                setSelectedTickets([]);
              }
            }}
            selectedTicket={selectedTicket}
            onTicketClick={setSelectedTicket}
            onCloseDialog={() => setSelectedTicket(null)}
          />
        )}

        {activeView === 'incident' && (
          <StyledCard>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                New Incident
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <IncidentForm
                  formData={formData}
                  formErrors={formErrors}
                  onInputChange={handleInputChange}
                  selectedEquipmentType={selectedEquipmentType}
                  setSelectedEquipmentType={setSelectedEquipmentType}
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                />
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={submitting}
                  >
                    {submitting ? <CircularProgress size={24} color="inherit" /> : 'Submit Incident'}
                  </Button>
                </Grid>
              </Box>
            </CardContent>
          </StyledCard>
        )}

        {activeView === 'change' && (
          <StyledCard>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                New Change Request
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <ChangeForm
                  formData={formData}
                  formErrors={formErrors}
                  onInputChange={handleInputChange}
                />
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={submitting}
                  >
                    {submitting ? <CircularProgress size={24} color="inherit" /> : 'Submit Change Request'}
                  </Button>
                </Grid>
              </Box>
            </CardContent>
          </StyledCard>
        )}
      </Container>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </View>
  );
};

export default ServicePortal;