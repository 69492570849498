import React, { useState, useEffect } from "react";
import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, useMediaQuery, Typography } from "@mui/material";
import { fetchAuthSession } from "aws-amplify/auth";
import Incidents from "../Incidents/Incidents";
import Incident from "../Incidents/Incident";
import Services from "../ServiceRequests/Services";
import NewService from "../ServiceRequests/NewService";
import NewIncident from "../Incidents/NewIncident";
import Service from "../ServiceRequests/Service";
import Task from "../Task/Task";
import Reporting from "../Reporting/Reporting";
import CustomerPortal from "../CustomerPortal/CustomerPortal";
import ServicesCatalog from "../ServicesCatalog/ServicesCatalog";
import TaskEditor from "../ServicesCatalog/TaskEditor";
import PromptWindow from "../PromptWindow/PromptWindow";
import AssignmentGroupManagement from "../AssignmentGroups/AssignmentGroupManagement";
import AssignmentGroupDetails from "../AssignmentGroups/AssignmentGroupDetails";
import ProjectCreation from "../ProjectPortal/ProjectCreation";
import ProjectsTable from "../ProjectPortal/Projects";
import UserTasks from "../Task/UserTasks";
import AccessRights from "../AccessRights/AccessRights";
import { useUser } from "../../../UserContext";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Dashboard from "../User/Dashboard";
import UserIncidents from "../Incidents/UserIncidents";
import UserServices from "../ServiceRequests/UserServices";
import ControlTower from "../ControlTower/ControlTower";
import TechnicianCalendar from "../ControlTower/TechCalendar";
import ServicePortal from "../CustomerPortal/components/ServicePortal";

const versionNumber = "1.19";

// Component shown when user doesn't have valid domain access
const AccessDenied = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh',
      textAlign: 'center',
      p: 3
    }}
  >
    <Typography variant="h4" component="h1" gutterBottom>
      Access Denied
    </Typography>
    <Typography variant="body1">
      You do not have permission to access this application. 
      Please contact your administrator if you believe this is an error.
    </Typography>
  </Box>
);

const MainUI = ({ appName, toggleColorMode, colorMode }) => {
  const { user, allUsers } = useUser();
  const navigate = useNavigate();
  // Track user's email domain for access control
  const [userDomain, setUserDomain] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check user's email domain on component mount
  useEffect(() => {
    const checkUserDomain = async () => {
      try {
        // Get user's email from Cognito tokens
        const { tokens } = await fetchAuthSession();
        const email = tokens.idToken.payload.email;
        const domain = email.split('@')[1];
        setUserDomain(domain);
        
        // Restricted domains (acgbrands.com, icloud.com) only get service portal access
        // Example for multiple domains
        // if ((domain === 'acgbrands.com' || domain === 'icloud.com') && window.location.pathname !== '/service-portal') {
        if (domain === 'acgbrands.com' && window.location.pathname !== '/service-portal') {
          navigate('/service-portal');
        } 
        // Only allow cloudingenuity.com and restricted domains - all others get access denied
        else if (domain !== 'cloudingenuity.com' && domain !== 'acgbrands.com') {
          navigate('/access-denied');
        }
      } catch (error) {
        console.error('Error fetching auth session:', error);
        navigate('/access-denied');
      } finally {
        setIsLoading(false);
      }
    };

    checkUserDomain();
  }, [navigate]);

  // Check if user has required access rights for features
  const hasAccess = (requiredAccess) => {
    if (!user || !user.AccessRights) return false;
    return (
      user.AccessRights.includes("SuperUser") ||
      user.AccessRights.includes(requiredAccess)
    );
  };

  // Menu items with their required access rights
  const menuItems = [
    { label: "Dashboard", route: "/", access: "Incidents" },
    { label: "Incidents", route: "/incidents", access: "Incidents" },
    { label: "Reporting", route: "/reporting", access: "Reporting" },
    { label: "Services", route: "/services", access: "Super User" },
    { label: "Customer Portal", route: "/customer-portal", access: "Customer" },
    { label: "Service Catalog", route: "/serviceCatalog", access: "ServiceCatalog" },
    { label: "IngenuityAI", route: "/prompt", access: "AI" },
    { label: "Assignment Groups", route: "/assignmentGroups", access: "AssignmentGroups" },
    { label: "Project Creation", route: "/projectCreation", access: "Projects" },
    { label: "Projects", route: "/projects", access: "Projects" },
    { label: "Scheduler", route: "/scheduler", access: "Scheduling" },
    { label: "User Tasks", route: "/userTasks", access: "Tasks" },
    { label: "Access Rights", route: "/accessRights", access: "SuperUser" },
    { label: "My Incidents", route: "/userIncidents", access: "Incidents" },
    { label: "My Services", route: "/userServices", access: "Services" },
    { label: "Control Tower Test", route: "/controlTower", access: "Super User" },
    { label: "Tech Calendar", route: "/calendar", access: "Super User" },
    { label: "ACG Service Portal", route: "/service-portal", access: "Super User" }
  ];

  const theme = createTheme({
    palette: {
      mode: colorMode,
    },
  });

  if (isLoading) {
    return null;
  }

  // Restricted domains only see service portal
  if (userDomain === 'acgbrands.com') {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <ResponsiveAppBar
            toggleColorMode={toggleColorMode}
            colorMode={colorMode}
            versionNumber={versionNumber}
            menuItems={[]}
            hasAccess={() => true}
            appName="ACG Service Portal"
          />
          <Routes>
            <Route path="/service-portal" element={<ServicePortal />} />
            <Route path="*" element={<Navigate to="/service-portal" replace />} />
          </Routes>
        </Box>
      </ThemeProvider>
    );
  }

  // cloudingenuity.com users get full app access based on their permissions
  if (userDomain === 'cloudingenuity.com') {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <ResponsiveAppBar
            toggleColorMode={toggleColorMode}
            colorMode={colorMode}
            versionNumber={versionNumber}
            menuItems={menuItems}
            hasAccess={hasAccess}
            appName={appName}
          />

          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/incidents" element={<Incidents colorMode={colorMode} />} />
              <Route path="/userIncidents" element={<UserIncidents colorMode={colorMode} />} />
              <Route path="/incident/:incidentID" element={<Incident />} />
              <Route path="/services" element={<Services colorMode={colorMode} />} />
              <Route path="/new-service" element={<NewService colorMode={colorMode} />} />
              <Route path="/userServices" element={<UserServices colorMode={colorMode} />} />
              <Route path="/new-incident" element={<NewIncident colorMode={colorMode} />} />
              <Route path="/service/:serviceID" element={<Service colorMode={colorMode} />} />
              <Route path="/task/:taskID/:serviceID" element={<Task colorMode={colorMode} />} />
              <Route path="/reporting" element={<Reporting colorMode={colorMode} />} />
              <Route path="/customer-portal" element={<CustomerPortal colorMode={colorMode} />} />
              <Route path="/serviceCatalog" element={<ServicesCatalog colorMode={colorMode} />} />
              <Route path="/tasks/:categoryName/:serviceId" element={<TaskEditor />} />
              <Route path="/prompt" element={<PromptWindow />} />
              <Route path="/assignmentGroups" element={<AssignmentGroupManagement colorMode={colorMode} />} />
              <Route path="/assignmentGroups/:groupName/members" element={<AssignmentGroupDetails colorMode={colorMode} />} />
              <Route path="/projectCreation" element={<ProjectCreation colorMode={colorMode} />} />
              <Route path="/projectCreation/:projectNumber" element={<ProjectCreation colorMode={colorMode} />} />
              <Route path="/projects" element={<ProjectsTable colorMode={colorMode} />} />
              <Route path="/userTasks" element={<UserTasks colorMode={colorMode} />} />
              <Route path="/accessRights" element={<AccessRights colorMode={colorMode} />} />
              <Route path="/controlTower" element={<ControlTower colorMode={colorMode} />} />
              <Route path="/calendar" element={<TechnicianCalendar colorMode={colorMode} />} />
              <Route path="/service-portal" element={<ServicePortal />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }

  // All other domains see access denied
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<Navigate to="/access-denied" replace />} />
      </Routes>
    </ThemeProvider>
  );
};

export default MainUI;