// src/utils/validators.js
export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  
  export const validatePhone = (phone) => {
    const re = /^\+?1?\d{10}$/;
    return re.test(phone.replace(/[^0-9]/g, ''));
  };
  
  export const validateName = (name) => {
    return name.length >= 2 && /^[a-zA-Z\s-]+$/.test(name);
  };