import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { changeTypeOptions } from '../utils/constants';

const ChangeForm = ({ 
  formData = {}, 
  formErrors = {}, 
  onInputChange
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel>Change Type</InputLabel>
          <Select
            value={formData.changeType || ''}
            onChange={(e) => onInputChange('changeType', e.target.value)}
            label="Change Type"
            error={!!formErrors.changeType}
          >
            {changeTypeOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          label="Title"
          value={formData.title || ''}
          onChange={(e) => onInputChange('title', e.target.value)}
          error={!!formErrors.title}
          helperText={formErrors.title}
          placeholder="Brief description of the change"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          multiline
          rows={3}
          label="Justification"
          value={formData.justification || ''}
          onChange={(e) => onInputChange('justification', e.target.value)}
          error={!!formErrors.justification}
          helperText={formErrors.justification}
          placeholder="Business justification for the change"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          multiline
          rows={4}
          label="Description"
          value={formData.description || ''}
          onChange={(e) => onInputChange('description', e.target.value)}
          error={!!formErrors.description}
          helperText={formErrors.description}
          placeholder="Detailed explanation of the change"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          type="datetime-local"
          label="Planned Start"
          value={formData.plannedStart || ''}
          onChange={(e) => onInputChange('plannedStart', e.target.value)}
          error={!!formErrors.plannedStart}
          helperText={formErrors.plannedStart}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          type="datetime-local"
          label="Planned End"
          value={formData.plannedEnd || ''}
          onChange={(e) => onInputChange('plannedEnd', e.target.value)}
          error={!!formErrors.plannedEnd}
          helperText={formErrors.plannedEnd}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="First Name"
          value={formData.firstName || ''}
          onChange={(e) => onInputChange('firstName', e.target.value)}
          error={!!formErrors.firstName}
          helperText={formErrors.firstName}
          placeholder="John"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Last Name"
          value={formData.lastName || ''}
          onChange={(e) => onInputChange('lastName', e.target.value)}
          error={!!formErrors.lastName}
          helperText={formErrors.lastName}
          placeholder="Doe"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          type="email"
          label="Email"
          value={formData.requestedBy || ''}
          onChange={(e) => onInputChange('requestedBy', e.target.value)}
          error={!!formErrors.requestedBy}
          helperText={formErrors.requestedBy}
          placeholder="your.email@company.com"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Phone Number"
          value={formData.phoneNumber || ''}
          onChange={(e) => onInputChange('phoneNumber', e.target.value)}
          error={!!formErrors.phoneNumber}
          helperText={formErrors.phoneNumber}
          placeholder="1234567890"
        />
      </Grid>
    </Grid>
  );
};

export default ChangeForm;