// src/components/IncidentForm.js
import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { incidentPriorityOptions, equipmentTypes } from "../utils/constants";

const IncidentForm = ({
  formData,
  formErrors,
  onInputChange,
  selectedEquipmentType,
  setSelectedEquipmentType,
  selectedModel,
  setSelectedModel,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth required error={!!formErrors.priority}>
          <InputLabel>Priority</InputLabel>
          <Select
            value={formData.priority || ""}
            onChange={(e) => onInputChange("priority", e.target.value)}
            label="Priority"
          >
            {incidentPriorityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {formErrors.priority && (
            <FormHelperText>{formErrors.priority}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          label="Title"
          value={formData.title || ""}
          onChange={(e) => onInputChange("title", e.target.value)}
          placeholder="Brief description of the issue"
          error={!!formErrors.title}
          helperText={formErrors.title}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          multiline
          rows={4}
          label="Description"
          value={formData.description || ""}
          onChange={(e) => {
            if (e.target.value.length <= 256) {
              onInputChange("description", e.target.value);
            }
          }}
          inputProps={{ maxLength: 256 }}
          placeholder="Detailed explanation of the issue, including any error messages or symptoms"
          error={!!formErrors.description}
          helperText={
            formErrors.description ||
            `${(formData.description || "").length}/256 characters`
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth required error={!!formErrors.equipmentType}>
          <InputLabel>Equipment Type</InputLabel>
          <Select
            value={selectedEquipmentType || ""}
            onChange={(e) => {
              setSelectedEquipmentType(e.target.value);
              setSelectedModel("");
            }}
            label="Equipment Type"
          >
            {equipmentTypes.map((equipment) => (
              <MenuItem key={equipment.type} value={equipment.type}>
                {equipment.type}
              </MenuItem>
            ))}
          </Select>
          {formErrors.equipmentType && (
            <FormHelperText>{formErrors.equipmentType}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl
          fullWidth
          required
          disabled={!selectedEquipmentType}
          error={!!formErrors.equipmentModel}
        >
          <InputLabel>Equipment Model</InputLabel>
          <Select
            value={selectedModel || ""}
            onChange={(e) => setSelectedModel(e.target.value)}
            label="Equipment Model"
          >
            {selectedEquipmentType &&
              equipmentTypes
                .find((eq) => eq.type === selectedEquipmentType)
                ?.models.map((model) => (
                  <MenuItem key={model} value={model}>
                    {model}
                  </MenuItem>
                ))}
          </Select>
          {formErrors.equipmentModel && (
            <FormHelperText>{formErrors.equipmentModel}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="First Name"
          value={formData.firstName || ""}
          onChange={(e) => onInputChange("firstName", e.target.value)}
          error={!!formErrors.firstName}
          helperText={formErrors.firstName}
          placeholder="John"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Last Name"
          value={formData.lastName || ""}
          onChange={(e) => onInputChange("lastName", e.target.value)}
          error={!!formErrors.lastName}
          helperText={formErrors.lastName}
          placeholder="Doe"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          type="email"
          label="Email"
          value={formData.requestedBy || ""}
          onChange={(e) => onInputChange("requestedBy", e.target.value)}
          error={!!formErrors.requestedBy}
          helperText={formErrors.requestedBy}
          placeholder="your.email@company.com"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Phone Number"
          value={formData.phoneNumber || ""}
          onChange={(e) => onInputChange("phoneNumber", e.target.value)}
          error={!!formErrors.phoneNumber}
          helperText={formErrors.phoneNumber}
          placeholder="1234567890"
        />
      </Grid>
    </Grid>
  );
};

export default IncidentForm;
