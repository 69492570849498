import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { fetchUsers } from './components/ApiServices/ApiServices';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const licenseKey = process.env.REACT_APP_LICENSE_KEY;
  const [allUsers, setUsers] = useState([]);

  const fetchAllUsers = async (email, domain) => {
    try {
      if (domain === 'cloudingenuity.com') {
        const users = await fetchUsers(licenseKey);
        const requester = users.response.find(user => user.EmailAddress === email);
        setUser(requester);
        setUsers(users);
        localStorage.setItem("user", JSON.stringify(requester));
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const checkAndSetUser = async () => {
      try {
        localStorage.removeItem("user");
        localStorage.removeItem("users");
        setUser(null);
        setUsers([]);

        const { tokens } = await fetchAuthSession();
        const email = tokens.idToken.payload.email;
        const domain = email.split('@')[1];
        await fetchAllUsers(email, domain);
      } catch (error) {
        console.error("Error checking user:", error);
      }
    };

    checkAndSetUser();
  }, []);

  const clearUser = () => {
    setUser(null);
    setUsers([]);
    localStorage.removeItem("user");
    localStorage.removeItem("users");
  };

  return (
    <UserContext.Provider value={{ allUsers, user, setUser, fetchAllUsers, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);