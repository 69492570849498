// src/components/TicketDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Chip, Paper } from '@mui/material';
import { STATUS_COLORS, PRIORITY_COLORS } from '../utils/constants';

const TicketDialog = ({ ticket, onClose }) => {
  if (!ticket) return null;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {ticket.type} Details - {ticket.ticketNumber}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">Status</Typography>
            <Chip
              label={ticket.status}
              style={{
                backgroundColor: STATUS_COLORS[ticket.status],
                color: 'white'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">Priority</Typography>
            <Chip
              label={ticket.priority}
              style={{
                backgroundColor: PRIORITY_COLORS[ticket.priority],
                color: 'white'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Description</Typography>
            <Typography>{ticket.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Status History</Typography>
            {ticket.lifespan.status.map((status, index) => (
              <Paper key={index} sx={{ p: 2, mb: 1, bgcolor: 'background.default' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="primary">
                      {status.status}
                    </Typography>
                    <Typography variant="caption">
                      {status.timestamp} - {status.user}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>
          {ticket.type === 'Change' && (
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Change Type</Typography>
                <Typography>{ticket.rawData.ChangeType}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Implementation Plan</Typography>
                <Typography>{ticket.rawData.ImplementationPlan || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Backout Plan</Typography>
                <Typography>{ticket.rawData.BackoutPlan || 'Not specified'}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketDialog;