import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { ReactComponent as ACGLogo } from '../../Graphics/ACGLogo.svg';
import { ReactComponent as TAMULogo } from '../../Graphics/Texas_A&M_University_seal.svg';

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

export const ResponsiveLogo = styled(ACGLogo)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: "auto",
  height: '128px'
}));

export const ResponsiveLogoB = styled(TAMULogo)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: "auto",
  height: '128px'
}));