// src/components/TicketTable.js
import React from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableSortLabel, 
  Checkbox, 
  Chip, 
  Typography, 
  Tooltip,
  TableContainer,
  TablePagination,
  Paper 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { STATUS_COLORS, PRIORITY_COLORS } from '../utils/constants';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer'
  }
}));

const TicketTable = ({
  tickets,
  selectedTickets,
  onTicketSelect,
  onSelectAll,
  onTicketClick,
  orderBy,
  order,
  onSort,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectedTickets.length > 0 && selectedTickets.length < tickets.length}
                checked={selectedTickets.length === tickets.length}
                onChange={onSelectAll}
              />
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'ticketNumber'}
                direction={orderBy === 'ticketNumber' ? order : 'asc'}
                onClick={() => onSort('ticketNumber')}
              >
                Ticket #
              </TableSortLabel>
            </TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Priority</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Opened</TableCell>
            <TableCell>Last Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((ticket) => (
              <StyledTableRow 
                key={ticket.ticketNumber}
                onClick={() => onTicketClick(ticket)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedTickets.includes(ticket.ticketNumber)}
                    onChange={(e) => {
                      e.stopPropagation();
                      onTicketSelect(ticket.ticketNumber);
                    }}
                  />
                </TableCell>
                <TableCell>{ticket.ticketNumber}</TableCell>
                <TableCell>{ticket.type}</TableCell>
                <TableCell>
                  <Chip
                    label={ticket.priority}
                    size="small"
                    style={{
                      backgroundColor: PRIORITY_COLORS[ticket.priority],
                      color: 'white'
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={ticket.status}
                    size="small"
                    style={{
                      backgroundColor: STATUS_COLORS[ticket.status],
                      color: 'white'
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title={ticket.description}>
                    <Typography noWrap sx={{ maxWidth: 200 }}>
                      {ticket.description}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>{new Date(parseInt(ticket.opened)).toLocaleString('en-US')}</TableCell>
                <TableCell>{ticket.lastUpdate}</TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tickets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default TicketTable;