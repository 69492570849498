// src/api/PortalAPI.js
const API_BASE = 'https://9ot5eommal.execute-api.us-east-2.amazonaws.com/Prod';

export const fetchIncidents = async () => {
  try {
    console.log("Fetching Incidents...");
    const response = await fetch(`${API_BASE}/GET_INCIDENTS`);
    const data = await response.json();
    const parsedBody = JSON.parse(data.body);
    return parsedBody.items.map(incident => {
      const lifespanData = JSON.parse(incident.Lifespan);
      const lastStatus = lifespanData.status[lifespanData.status.length - 1];
      
      return {
        ticketNumber: incident.IncidentNumber,
        type: 'Incident',
        priority: incident.Priority,
        status: incident.IncidentStatus,
        description: incident.IncidentDescription,
        opened: incident.CreationTime,
        lastUpdate: lastStatus.timestamp,
        lifespan: lifespanData,
        rawData: incident
      };
    });
  } catch (err) {
    console.error('Error fetching incidents:', err);
    throw err;
  }
};

export const fetchChanges = async () => {
  try {
    console.log("Fetching Changes...")
    const response = await fetch(`${API_BASE}/GET_CHANGES`);
    const data = await response.json();
    const parsedBody = JSON.parse(data.body);
    return parsedBody.items.map(change => {
      const lifespanData = JSON.parse(change.Lifespan);
      const lastStatus = lifespanData.status[lifespanData.status.length - 1];
      
      return {
        ticketNumber: change.ChangeNumber,
        type: 'Change',
        priority: change.Priority,
        status: change.ChangeStatus,
        description: change.ChangeDescription,
        opened: change.CreationTime,
        lastUpdate: lastStatus.timestamp,
        lifespan: lifespanData,
        rawData: change
      };
    });
  } catch (err) {
    console.error('Error fetching changes:', err);
    throw err;
  }
};

// Add SLA calculation utility function
const calculateResolutionTime = (priority) => {
  const currentTime = new Date().getTime();
  const oneHour = 3600000; // milliseconds in an hour
  const oneDay = 86400000;  // milliseconds in a day

  switch (priority) {
    case 'Priority 1 - Critical':
      return (currentTime + (8 * oneHour)).toString(); // 8 hours
    case 'Priority 2 - High':
      return (currentTime + (16 * oneHour)).toString(); // 16 hours
    case 'Priority 3 - Medium':
      return (currentTime + (3 * oneDay)).toString(); // 3 days
    case 'Priority 4 - Low':
      return (currentTime + (7 * oneDay)).toString(); // 7 days
    default:
      return (currentTime + (7 * oneDay)).toString(); // default to 7 days
  }
};

export const submitIncident = async (formData) => {
  console.log("Submitting Incident for ACG:", formData);
  const creationTime = new Date().getTime().toString();
  const slaTarget = calculateResolutionTime(formData.priority);

  const payload = {
    AssignedTo: "unassigned@cloudingenuity.com",
    AssignedGroup: "AG-NAAS-ACG-INC",
    CreationTime: creationTime,
    DueDate: "NA",
    Impact: formData.priority === "Priority 1 - Critical" ? "High" : 
           formData.priority === "Priority 2 - High" ? "Medium" : "Low",
    Priority: formData.priority,
    RelatedTickets: [],
    IncidentDescription: formData.description,
    IncidentStatus: "New",
    RequesterEmail: formData.requestedBy,
    RequesterName: `${formData.firstName} ${formData.lastName}`,
    RequesterPhoneNumber: formData.phoneNumber,
    IncidentType: formData.title,
    ConfigurationItem: `${formData.equipmentType} - ${formData.equipmentModel}`,
    SLATarget: slaTarget,
    Organization: "Cloud Ingenuity",
    Location: "Remote",
    Note: formData.description
  };

  const response = await fetch('https://9ot5eommal.execute-api.us-east-2.amazonaws.com/Prod/CREATE_INCIDENT', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to submit incident');
  } else if (response.ok) {
    console.log("Incident Submitted Successfully");
  }

  return response.json();
};

export const submitChange = async (formData) => {
  const creationTime = new Date().getTime().toString();

  const payload = {
    AssignedTo: "unassigned@cloudingenuity.com",
    AssignedGroup: "AG-NAAS-ACG-CHG",
    CreationTime: creationTime,
    ChangeDescription: formData.description,
    RequestedBy: formData.requestedBy,
    DateOfRequest: creationTime,
    Priority: formData.changeType, // Map changeType to Priority
    ImpactAssessment: formData.justification,
    RiskAnalysis: "Pending Analysis",
    ChangeType: formData.changeType,
    ChangeOwner: `${formData.firstName} ${formData.lastName}`,
    ImplementationPlan: formData.description,
    BackoutPlan: "Standard Backout Procedures",
    Approvals: "[]",
    Attachments: "[]",
    ChangeStatus: "New",
    ClosureDetails: "",
    PlannedStart: formData.plannedStart,
    PlannedEnd: formData.plannedEnd,
    DueDate: formData.plannedEnd,
    RequesterName: `${formData.firstName} ${formData.lastName}`,
    RequesterPhoneNumber: formData.phoneNumber,
    RequesterEmail: formData.requestedBy,
    NotesLog: formData.description
  };

  const response = await fetch('https://9ot5eommal.execute-api.us-east-2.amazonaws.com/Prod/CREATE_CHANGE', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });

  if (!response.ok) throw new Error(await response.json().message || 'Failed to submit change request');
  return response.json();
};