// src/utils/constants.js
export const PRIORITY_COLORS = {
    'Priority 1 - Critical': '#D32F2F',
    'Priority 2 - High': '#ED6C02',
    'Priority 3 - Medium': '#ED6C02',
    'Priority 4 - Low': '#0288D1'
  };
  
  export const STATUS_COLORS = {
    'New': '#0288D1',
    'In Progress': '#ED6C02',
    'Pending': '#9C27B0',
    'Resolved': '#2E7D32',
    'Closed': '#757575'
  };
  
  export const TICKET_TYPES = {
    ALL: 'all',
    INCIDENTS: 'incidents',
    CHANGES: 'changes'
  };
  
  export const equipmentTypes = [
    {
      type: 'Switches(L2)',
      models: ['WS-C3850', 'CBS250', 'WS-C3560', 'C9300 series', 'EX4100 Series']
    },
    {
      type: 'Router(L3)',
      models: ['EX4400 Series']
    },
    {
      type: 'Wireless Access Points',
      models: ['C9120AXI-B', 'AIR-AP2802E-B-K9 Series', 'AP63E', 'AP45 Series']
    },
    {
      type: 'Wireless Controller',
      models: ['Cisco 9800-L Controller']
    },
    {
      type: 'Firewalls',
      models: ['Cisco ASA', 'Fortinet Firewalls', 'SRX1600 Series']
    }
  ];
  
  export const changeTypeOptions = [
    { value: 'standard', label: 'Standard Change - Pre-approved, low-risk, routine' },
    { value: 'normal', label: 'Normal Change - Planned change requiring approval' },
    { value: 'emergency', label: 'Emergency Change - Urgent, high-risk requiring immediate action' }
  ];
  
  export const incidentPriorityOptions = [
    { value: 'Priority 1 - Critical', label: 'P1 - Complete Service Outage affecting all users' },
    { value: 'Priority 2 - High', label: 'P2 - Partial Service outage affecting multiple departments' },
    { value: 'Priority 3 - Medium', label: 'P3 - Single department/team service disruption' },
    { value: 'Priority 4 - Low', label: 'P4 - Minor issues with workarounds available' }
  ];